import { Center, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import EmployerAboutCard from "../../components/cards/EmployerAboutCard";
import EmployerProfileCard from "../../components/cards/EmployerProfileCard";
import { Page } from "../../components/Page";
import { getEmployerProfile } from "../../services/employer-api";

const profileImage = null;

export function EmployerProfilePage() {
  const { data: profileData, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profileData"],
    queryFn: getEmployerProfile,
    refetchOnWindowFocus: "always"
  });

  if (isProfileLoading || !profileData) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return (
    <Page>
      <EmployerProfileCard profileData={profileData} profileImage={profileImage} />

      <EmployerAboutCard profileData={profileData} />
    </Page>
  );
}