import type { PathRouteProps } from "react-router-dom";

import UserRoleRedirect from "./UserRoleRedirect";
import { RoutePath } from "../../../../shared/models";
import AccountPage from "../pages/AccountPage";
import { AdminPage } from "../pages/admin";
import MagicPage from "../pages/ai-coach/AICoachMagicPage";
import { AthleteDashboardPage } from "../pages/athlete/AthleteDashboardPage";
import { AthleteProfilePage } from "../pages/athlete/AthleteProfilePage";
import { SpecificAthleteProfilePage } from "../pages/athlete/SpecificAthleteProfilePage";
import { EmployerDashboardPage } from "../pages/employer/EmployerDashboardPage";
import { EmployerProfilePage } from "../pages/employer/EmployerProfilePage";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import { FindAthletesPage } from "../pages/find-athletes";
import { UniversityDashboardPage } from "../pages/UniversityDashboardPage";

interface HCAPathRouteProps extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<HCAPathRouteProps> = [];

export const privateRoutes: Array<HCAPathRouteProps> = [
  {
    path: RoutePath.ROOT,
    element: <UserRoleRedirect />
  },
  {
    path: RoutePath.ATHLETE_DASHBOARD,
    element: <AthleteDashboardPage />
  },
  {
    path: RoutePath.ATHLETE_PROFILE,
    element: <AthleteProfilePage />
  },
  {
    path: RoutePath.UNIVERSITY_DASHBOARD,
    element: <UniversityDashboardPage />
  },
  {
    path: RoutePath.EMPLOYER_DASHBOARD,
    element: <EmployerDashboardPage />
  },
  {
    path: RoutePath.FIND_ATHLETES,
    element: <FindAthletesPage />
  },
  {
    path: RoutePath.EMPLOYER_PROFILE,
    element: <EmployerProfilePage />
  },
  {
    path: RoutePath.ADMIN,
    element: <AdminPage />
  },
  {
    path: RoutePath.COACH,
    element: <MagicPage />
  },
  {
    path: RoutePath.ACCOUNT,
    element: <AccountPage />,
    bodyPadding: [0],
    bgColor: "white"
  },
  {
    path: RoutePath.SPECIFIC_ATHLETE_PROFILE,
    element: <SpecificAthleteProfilePage />
  },
  {
    path: RoutePath.FEEDBACK,
    element: <FeedbackPage />
  },
];
