import { useEffect, useState } from "react";

import { ArrowForwardIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  VStack,
  useToast,
  Tooltip,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Hide,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { Gender, RoutePath, University, UniversityAthleteCount, UserProfileSearchResult, UserRole } from "@shared/models";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Select as ReactSelect } from "chakra-react-select";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { TbBrandLinkedin } from "react-icons/tb";
import { Navigate, useNavigate } from "react-router-dom";

import { UpdateUserRole } from "./UpdateUserRole";
import { useAppStore } from "../../app-store";
import AthleteFilter from "../../components/AthleteFilter";
import { getHCAInsights } from "../../services/admin-api";
import { getSports } from "../../services/hca-api";
import { fetchAllProfiles } from "../../services/search-athlete-api";
import { getAllUniversities, updatePrimaryUniversity } from "../../services/university-api";

export interface HCAInsights {
  universityAthleteCounts: UniversityAthleteCount[];
}

type Filters = {
  graduationYear: string;
  primarySport: string;
  major: string;
  readyToWork: boolean;
  gender: Gender;
  hidePendingAthletes: boolean;
}

const PAGE_SIZE = 15;

export const AdminPage = () => {
  const navigate = useNavigate();
  const isMobileView = window.innerWidth <= 768;
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [allProfiles, setAllProfiles] = useState<UserProfileSearchResult[]>([]);
  const [personalUniversity, setPersonalUniversity] = useState<University | null>(null);
  const [hcaInsights, setHCAInsights] = useState<HCAInsights | null>(null);
  const [showAdminActions, setShowAdminActions] = useState(!isMobileView);
  const [showUniversityAthleteCounts, setShowUniversityAthleteCounts] = useState(!isMobileView);
  const toast = useToast();
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<Filters | Record<string, never>>({});

  const {
    data: athleteInfo,
    refetch: fetchAthleteInfo,
    isFetching: isFetchingAthleteInfo,
  } = useInfiniteQuery({
    queryKey: [],
    queryFn: () =>
      fetchAllProfiles({ search: searchValue, page: currentPage, pageSize: PAGE_SIZE, ...filters }),
    getNextPageParam: (lastPage, group, lastPageParam) => {
      return lastPage.totalPages > lastPage.currentPage ? lastPageParam + 1 : undefined;
    },
    initialPageParam: 1,
    refetchOnWindowFocus: false
  });

  const totalPages = athleteInfo?.pages?.[0]?.totalPages || 1;

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  useEffect(() => {
    const profiles = athleteInfo?.pages?.[0].users;
    if (profiles) setAllProfiles(profiles);
  }, [athleteInfo]);

  useEffect(() => {
    if (filters) fetchAthleteInfo();
  }, [filters, currentPage]);

  const { data: universities } = useQuery({
    queryKey: ["universities"],
    queryFn: getAllUniversities,
  });

  const { data: hcaInsightsData } = useQuery<HCAInsights>({
    queryKey: ["hcaInsights"],
    queryFn: getHCAInsights
  });

  useEffect(() => {
    if (hcaInsightsData) setHCAInsights(hcaInsightsData);
  }, [hcaInsightsData]);


  const {
    data: sportList,
  } = useQuery({
    queryKey: ["sports"],
    queryFn: getSports,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (name: keyof Filters) => (selectedOption: any) => {
    setFilters((prev) => ({
      ...prev,
      graduationYear: prev?.graduationYear,
      primarySport: prev?.primarySport,
      major: prev?.major,
      readyToWork: prev?.readyToWork,
      gender: prev?.gender,
      hidePendingAthletes: prev?.hidePendingAthletes,
      [name]: selectedOption?.value,
    }));
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSubmit = () => {
    fetchAthleteInfo();
  };

  const { mutate: updateAdminPrimaryUniversity } = useMutation({
    mutationFn: updatePrimaryUniversity,
    onSuccess: () => {
      toast({
        title: "Primary university updated",
        status: "success",
        duration: 3000,
        position: "top",
      });
      queryClient.invalidateQueries({ queryKey: ["universities"] });
    }
  })

  const changePrimaryUniversity = (option: University | null) => {
    if (!option || !option.id) return;
    updateAdminPrimaryUniversity(option.id)
    setPersonalUniversity(option);
  }

  useEffect(() => {
    if (!universities) return;
    const personalUniversity = universities.find((uni) => uni.id === userInfo?.primaryUniversityId);
    setPersonalUniversity(personalUniversity ?? null);
  }, [universities, userInfo]);

  // TODO: We should add page guards at a higher level
  if (userInfo && userInfo.role !== UserRole.ADMIN) {
    return <Navigate to={RoutePath.ROOT} />;
  }

  if (!athleteInfo) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return (
    <Box>
      <VStack gap={5} align={"flex-start"}>
        <Stack w={"100%"} direction={["column", "row"]} maxHeight={"300px"} justifyContent={"space-between"} pr={4} overflowY={"auto"}>
          <VStack alignItems={"flex-start"} w={["100%", "33%"]}>
            <Heading fontSize={["2xl", "4xl"]} fontWeight="bold" textAlign={["center", "left"]} mb={2}>
              HCA Insights
            </Heading>
            <Divider display={"block"} />
            <HStack justifyContent={"space-between"} w={"full"}>
              <Heading fontSize={"lg"} mb={2}>Admin Actions</Heading>
              <Button size={"sm"} variant={"ghost"} onClick={() => setShowAdminActions(!showAdminActions)}>{showAdminActions ? "hide" : "show"}</Button>
            </HStack>
            {showAdminActions && (
              <>
                <UpdateUserRole />
                {universities && userInfo && (
                  <FormControl>
                    <FormLabel>Personal University Dashboard School</FormLabel>
                    <ReactSelect
                      options={universities}
                      value={personalUniversity}
                      getOptionLabel={(option) => option.universityName ?? ""}
                      getOptionValue={(option) => option.id.toString()}
                      onChange={(option) => changePrimaryUniversity(option)}
                    />
                  </FormControl>
                )}
              </>
            )}
          </VStack>
          {hcaInsights && <VStack alignItems={"flex-start"} w={["100%", "40%"]} overflowY={"auto"}>
            <HStack justifyContent={"space-between"} w={"full"}>
              <Heading fontSize={"lg"} mb={2}>University Athlete Counts</Heading>
              <Button size={"sm"} variant={"ghost"} onClick={() => setShowUniversityAthleteCounts(!showUniversityAthleteCounts)}>{showUniversityAthleteCounts ? "hide" : "show"}</Button>
            </HStack>
            {showUniversityAthleteCounts && hcaInsights?.universityAthleteCounts.filter((uni) => uni.universityName).sort((a, b) => b.athleteCount - a.athleteCount).map((uni) => (
              <HStack key={uni.universityId} justifyContent={"space-between"} w={"full"}>
                <Text fontSize={"sm"}>{uni.universityName}</Text>
                <Text fontSize={"sm"}>{uni.athleteCount}</Text>
              </HStack>
            ))}
          </VStack>
          }
        </Stack>

        <Divider display={"block"} />
        <Heading fontSize={"lg"} mb={2}>Athlete List</Heading>
        <AthleteFilter
          sportList={sportList}
          onFilterChange={(name: string) => handleFilterChange(name as keyof Filters)} // Change type to string
          handleSearchChange={handleSearchChange}
          handleSubmit={handleSubmit}
        // showUnclaimedAthletesFilter
        />

        {isFetchingAthleteInfo && <Spinner alignSelf="center" />}
        {!isFetchingAthleteInfo && (
          <Table variant="simple" colorScheme='brand' size={['sm', 'md']} overflowX="auto">
            {!isFetchingAthleteInfo && allProfiles.length === 0 && <TableCaption>0 Athletes Found</TableCaption>}
            <Thead>
              <Tr>
                <Th>Athlete Name</Th>
                <Th display={["none", "table-cell"]} isNumeric>Graduation</Th>
                <Th display={["none", "table-cell"]}>Sport</Th>
                <Th display={["none", "table-cell"]}>Major</Th>
                <Th display={["none", "table-cell"]}>University</Th>
                <Th display={["none", "table-cell"]} isNumeric>GPA</Th>
                <Th display={["none", "table-cell"]}><Tooltip label="Resume | LinkedIn | Email | Ready to Work">Quick Actions</Tooltip></Th>
                <Th display={["table-cell", "none"]}><Tooltip label="LinkedIn | Profile">Quick Actions</Tooltip></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isFetchingAthleteInfo && allProfiles.map((profile) => (
                <Tr key={profile.id}>
                  <Td display={["table-cell", "none"]}>
                    <Popover size="lg">
                      <PopoverTrigger>
                        <IconButton
                          icon={<SearchIcon />}
                          aria-label="Athlete Info"
                          variant="ghost"
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverHeader fontWeight="semibold">Athlete Info</PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton bg="brand.primary" color="white" />
                        <PopoverBody>
                          <Text><strong>University:</strong> {universities?.find((uni) => uni.id === profile.universityId)?.universityName || "-"}</Text>
                          <Text><strong>Major:</strong> {profile.major || "-"}</Text>
                          <Text><strong>Sport:</strong> {profile.primarySport || "-"}</Text>
                          <Text><strong>Graduation Year:</strong> {profile.graduationYear || "-"}</Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                    {profile.displayName}
                  </Td>
                  <Td display={["none", "table-cell"]}>{profile.displayName}</Td>
                  <Td display={["none", "table-cell"]} isNumeric>{profile.graduationYear || "-"}</Td>
                  <Td display={["none", "table-cell"]}>{profile.primarySport || "-"}</Td>
                  <Td display={["none", "table-cell"]}>{profile.major || "-"}</Td>
                  <Td display={["none", "table-cell"]}>{universities?.find((uni) => uni.id === profile.universityId)?.universityName || "-"}</Td>
                  <Td display={["none", "table-cell"]} isNumeric>{profile.gpa || "-"}</Td>
                  <Td>
                    <Box display="flex" gap={4} alignItems="center" justifyContent="space-around">
                      {/* <IconButton
                      icon={<TbFileText fontSize={32} />}
                      aria-label="Resume"
                      isDisabled={!profile.resumeUrl}
                      variant="ghost"
                      color={profile.resumeUrl ? "blue.500" : "gray.300"}
                      onClick={() => {
                        if (profile.resumeUrl) {
                          window.open(profile.resumeUrl, '_blank', 'noopener,noreferrer');
                        }
                      }}
                    /> */}
                      <IconButton
                        as="a"
                        icon={<TbBrandLinkedin fontSize={32} />}
                        aria-label="LinkedIn profile"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={profile.linkedInUrl ? (profile.linkedInUrl.startsWith('http') ? profile.linkedInUrl : `https://${profile.linkedInUrl}`) : undefined}
                        isDisabled={!profile.linkedInUrl}
                        variant="ghost"
                        color={profile.linkedInUrl ? "blue.500" : "gray.300"}
                      />
                      <Button
                        borderRadius="full"
                        pl={[0, 6]}
                        pr={[1, 5]}
                        variant="outline"
                        colorScheme='gray'
                        size='sm'
                        fontWeight='normal'
                        onClick={() => {
                          navigate(`/athlete/profile/${profile.userId}`);
                        }}
                      >
                        <Hide below="md">
                          View Profile
                        </Hide>
                        <Icon ml={1} as={ArrowForwardIcon} transform="rotate(-45deg)" />
                      </Button>
                      {/* <IconButton
                      as="a"
                      icon={<TbSend fontSize={30} />}
                      aria-label="Email"
                      href={`mailto:${profile.emailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      isDisabled={!profile.emailAddress}
                      variant="ghost"
                      color={profile.emailAddress ? "blue.500" : "gray.500"}
                    /> */}
                      <Hide below="md">
                        {profile.readyToWork ? <BsCheck2Circle fontSize={32} style={{ color: "green" }} /> : <BsCircle fontSize={30} style={{ color: "red" }} />}
                      </Hide>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {!isFetchingAthleteInfo && (
          <HStack mb={12} width="full" justifyContent="space-between" p={4} alignItems={"center"}>
            <HStack width="full" gap={2} alignItems={"center"}>
              <Text fontSize="sm">
                {athleteInfo?.pages?.[0]?.totalResults} Athletes
              </Text>
              <Text fontSize="sm">
                (Page {currentPage} of {totalPages})
              </Text>
            </HStack>

            <HStack justifyContent="flex-end" width="full" gap={[2, 4]}>
              <Button size={["sm", "md"]} variant="outline" onClick={handlePreviousPage} isDisabled={currentPage === 1}>
                Previous
              </Button>
              <Button size={["sm", "md"]} variant="outline" onClick={handleNextPage} isDisabled={currentPage === totalPages}>
                Next
              </Button>
            </HStack>
          </HStack>
        )}

      </VStack>
    </Box>
  );
};
