import React, { memo } from 'react';

import { Card, CardHeader, CardBody, Heading, HStack, VStack, Box, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GoCheck } from 'react-icons/go';

import { getFile, getProfileImage } from '../../services/file-api';
import { FileHandlingButton } from "../files/FileHandlingButton";

const FileTile: React.FC = () => {
  const queryClient = useQueryClient();

  const { data: profileImage } = useQuery({
    queryKey: ['profileImage'],
    queryFn: getProfileImage,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { data: resume } = useQuery({
    queryKey: ['resume'],
    queryFn: () => getFile('resume'),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const handleResumeUpload = () => {
    queryClient.invalidateQueries({ queryKey: ['resume'], exact: true });
  };

  const handleProfileImageUpload = () => {
    queryClient.invalidateQueries({ queryKey: ['profileImage'], exact: true });
  };

  let profileCompletion = 0;

  if (profileImage?.url) {
    profileCompletion += 50;
  }

  if (resume?.remoteFileName) {
    profileCompletion += 50;
  }

  let completionColor = "green.500";

  if (profileCompletion < 60) {
    completionColor = "orange.400";
  }

  return (
    <Card size={["md", "lg"]} w="full">
      <CardHeader paddingTop={2} paddingBottom={2} w="full">
        <HStack w="full" justifyContent={"space-between"}>
          <Heading size={["md"]}>Profile Files</Heading>
          <CircularProgress value={profileCompletion} color={completionColor} capIsRound>
            <CircularProgressLabel fontSize="xs" as='b'>{profileCompletion}%</CircularProgressLabel>
          </CircularProgress>
        </HStack>
      </CardHeader>
      <CardBody pt={2}>
        <VStack>
          <HStack justifyContent="space-between" w="full">
            {profileImage?.url ? (
              <Box w={9} h={9} pl={1.5}>
                <GoCheck color="green" fontSize="28" strokeWidth={.75} />
              </Box>
            ) : (
              <Box rounded="md" w={9} h={9} border="1px solid" borderColor="gray.400" />
            )}
            <Heading size={["md"]}>Headshot</Heading>
            <FileHandlingButton
              label="Headshot"
              category="profile-picture"
              includeImageCropper={true}
              action="upload"
              onFileUploaded={handleProfileImageUpload}
            />
          </HStack>
          <HStack justifyContent="space-between" w="full">
            {resume ? (
              <Box w={9} h={9} pl={1.5}>
                <GoCheck color="green" fontSize="28" strokeWidth={.75} />
              </Box>
              // <FileHandlingButton
              //     label="Resume"
              //     category="resume"
              //     action="download"
              // />
            ) : (
              <Box rounded="md" w={9} h={9} border="1px solid" borderColor="gray.400" />
            )}
            <Heading size="md">Resume</Heading>
            <FileHandlingButton
              label="Resume"
              category="resume"
              action="upload"
              onFileUploaded={handleResumeUpload}
            />
            {/* {resume ? (   //TODO: I'm not sure how we want to handle deleting resume's etc.
                            <FileHandlingButton
                                label="Resume"
                                category="resume"
                                action="delete"
                                onDelete={handleResumeUpload}
                            />
                        ) : (
                            <FileHandlingButton
                                label="Resume"
                                category="resume"
                                action="upload"
                                onFileUploaded={handleResumeUpload}
                            />
                        )} */}
          </HStack>
        </VStack>
      </CardBody>
    </Card >
  );
};

export default memo(FileTile);
